import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
var InfoInputs = (function (_super) {
    __extends(InfoInputs, _super);
    function InfoInputs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.disabledInputs = true;
        _this.userEdit = {
            displayName: '',
            phoneNumber: ''
        };
        _this.loading = false;
        return _this;
    }
    InfoInputs.prototype.toggleInputs = function () {
        this.userEdit = __assign(__assign({}, this.userEdit), this.user);
        this.disabledInputs = !this.disabledInputs;
    };
    InfoInputs.prototype.updateUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4, this.$store.dispatch('updateProfile', this.userEdit)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.toggleInputs();
                        return [2];
                }
            });
        });
    };
    InfoInputs = __decorate([
        Component({
            computed: mapState({
                user: function (_a) {
                    var _b = _a.user, displayName = _b.displayName, email = _b.email, phoneNumber = _b.phoneNumber, uid = _b.uid;
                    return ({
                        displayName: displayName,
                        email: email,
                        phoneNumber: phoneNumber,
                        uid: uid
                    });
                }
            })
        })
    ], InfoInputs);
    return InfoInputs;
}(Vue));
export default InfoInputs;
