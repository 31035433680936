var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InfoInputs" },
    [
      _c(
        "div",
        { staticClass: "form-header" },
        [
          _c("h3", { staticClass: "Account-title" }, [
            _vm._v("Account information")
          ]),
          _c(
            "VIcon",
            {
              staticClass: "Account-edit clickable",
              attrs: { color: "#3C3C3C" },
              on: {
                click: function($event) {
                  return _vm.toggleInputs()
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.disabledInputs ? "mdi-pen" : "mdi-close") + " "
              )
            ]
          )
        ],
        1
      ),
      _vm.disabledInputs
        ? _c(
            "div",
            { staticClass: "Account-inputs" },
            [
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: {
                  label: "First Name",
                  value: _vm.user.displayName,
                  filled: "",
                  readonly: _vm.disabledInputs
                }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: {
                  filled: "",
                  label: "Last Name",
                  readonly: _vm.disabledInputs
                }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: {
                  label: "Email",
                  type: "email",
                  value: _vm.user.email,
                  filled: "",
                  readonly: _vm.disabledInputs
                }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: {
                  label: "Phone number",
                  type: "tel",
                  filled: "",
                  value: _vm.user.phoneNumber,
                  readonly: _vm.disabledInputs
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "Account-inputs" },
            [
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: { filled: "", label: "First Name" },
                model: {
                  value: _vm.userEdit.displayName,
                  callback: function($$v) {
                    _vm.$set(_vm.userEdit, "displayName", $$v)
                  },
                  expression: "userEdit.displayName"
                }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: { label: "Last Name", readonly: "" }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: { label: "Email", type: "email", readonly: "" }
              }),
              _c("VTextField", {
                staticClass: "Account-inputs-input",
                attrs: { label: "Phone number", type: "tel", filled: "" },
                model: {
                  value: _vm.userEdit.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.userEdit, "phoneNumber", $$v)
                  },
                  expression: "userEdit.phoneNumber"
                }
              })
            ],
            1
          ),
      _c(
        "VBtn",
        {
          staticClass: "Account-btn",
          staticStyle: { color: "white" },
          attrs: {
            color: "#32a891",
            disabled: _vm.disabledInputs,
            loading: _vm.loading
          },
          on: { click: _vm.updateUser }
        },
        [_vm._v(" Update ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }