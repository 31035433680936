import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
var PasswordInputs = (function (_super) {
    __extends(PasswordInputs, _super);
    function PasswordInputs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.disabledInputs = true;
        _this.loading = false;
        _this.previousPassword = '';
        _this.password1 = '';
        _this.password2 = '';
        _this.showPassword1 = false;
        _this.showPassword2 = false;
        _this.rules = {
            required: function (value) { return !!value || 'Required.'; }
        };
        return _this;
    }
    Object.defineProperty(PasswordInputs.prototype, "passwordMatch", {
        get: function () {
            return this.password1 === this.password2 || "The email and password you entered don't match";
        },
        enumerable: false,
        configurable: true
    });
    PasswordInputs.prototype.validateInputs = function () {
        var _a = this, password1 = _a.password1, password2 = _a.password2, passwordMatch = _a.passwordMatch;
        this.$refs.passwordForm.validate();
        if (!password1 || !password2 || typeof passwordMatch === 'string')
            return;
        else
            this.updatePassword();
    };
    PasswordInputs.prototype.updatePassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.loading = true;
                this.$store.dispatch('updatePassword', this.password1);
                this.toggleInputs();
                this.loading = false;
                return [2];
            });
        });
    };
    PasswordInputs.prototype.toggleInputs = function () {
        this.disabledInputs = true;
        this.previousPassword = '';
        this.password1 = '';
        this.password2 = '';
    };
    PasswordInputs = __decorate([
        Component
    ], PasswordInputs);
    return PasswordInputs;
}(Vue));
export default PasswordInputs;
