import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import PasswordInputs from './Account/PasswordInput.vue';
import InfoInputs from './Account/InfoInput.vue';
var Notifications = (function (_super) {
    __extends(Notifications, _super);
    function Notifications() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Notifications = __decorate([
        Component({
            components: { PasswordInputs: PasswordInputs, InfoInputs: InfoInputs }
        })
    ], Notifications);
    return Notifications;
}(Vue));
export default Notifications;
